import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaTrash, FaArrowLeft } from 'react-icons/fa';

const QuestionDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get question ID from the URL params
  const [options, setOptions] = useState(['Option 1', 'Option 2']); // Example options

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const addOption = () => setOptions([...options, '']);
  const deleteOption = (index) => setOptions(options.filter((_, i) => i !== index));

  const saveOptions = () => {
    console.log('Options saved:', options);
    navigate(-1); // Go back to the previous page
  };

  return (
    <div className="p-8">
      <button
        className="mb-4 text-blue-500 flex items-center"
        onClick={() => navigate(-1)}
      >
        <FaArrowLeft className="mr-2" /> Back
      </button>

      <h1 className="text-2xl font-bold mb-4">Edit Question #{id}</h1>

      {options.map((option, index) => (
        <div key={index} className="flex items-center space-x-2 mb-2">
          <input
            type="text"
            value={option}
            onChange={(e) => handleOptionChange(index, e.target.value)}
            className="w-full border p-2 rounded-md"
          />
          <FaTrash
            className="cursor-pointer text-red-500"
            onClick={() => deleteOption(index)}
          />
        </div>
      ))}

      <button
        onClick={addOption}
        className="bg-blue-500 text-white px-4 py-2 rounded-md mb-4"
      >
        Add Option
      </button>

      <div className="flex space-x-4">
        <button
          onClick={() => navigate(-1)}
          className="bg-gray-500 text-white px-4 py-2 rounded-md"
        >
          Discard
        </button>
        <button
          onClick={saveOptions}
          className="bg-green-500 text-white px-4 py-2 rounded-md"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default QuestionDetail;
