import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const EditCategory = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const category = location.state;

  return (
    <div className="p-6 bg-gray-100">
      {/* Back Button */}
      <button
        className="mb-6 bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 flex items-center"
        onClick={() => navigate(-1)}
      >
        ← Back
      </button>

      {/* Edit Form */}
      <div className="bg-white shadow rounded p-4">
        <h1 className="text-2xl font-bold mb-4">{category.name}</h1>
        <label className="block mb-2 font-medium">Category Name</label>
        <input
          type="text"
          defaultValue={category.name}
          className="w-full border rounded px-4 py-2 mb-4"
        />

        <label className="block mb-2 font-medium">Description</label>
        <textarea
          defaultValue={category.description}
          className="w-full border rounded px-4 py-2 mb-4"
        />

        {/* Buttons */}
        <div className="flex space-x-4">
          <button
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
            onClick={() => console.log("Delete functionality")}
          >
            Delete
          </button>
          <button
            className="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300"
            onClick={() => navigate(-1)}
          >
            Discard
          </button>
          <button
            className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600"
            onClick={() => console.log("Save functionality")}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditCategory;
