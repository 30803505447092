import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const EpayeezzManagement = () => {
  const navigate = useNavigate();

  // Mock data for table
  const [data, setData] = useState([
    { id: "SW01", name: "Lorem Ipsum", can: "CAN123", status: "Verified" },
    { id: "SW02", name: "Lorem Ipsum", can: "CAN124", status: "Pending" },
    { id: "SW03", name: "Lorem Ipsum", can: "CAN125", status: "Verified" },
    { id: "SW04", name: "Lorem Ipsum2", can: "CAN126", status: "Rejected" },
    { id: "SW05", name: "Lorem Ipsum", can: "CAN127", status: "Verified" },
  ]);

  const [filter, setFilter] = useState("All");
  const [search, setSearch] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (e.target.closest(".filter-dropdown") === null) {
        setIsFilterOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  // Filter data based on filter and search
  const filteredData = data.filter((item) => {
    const matchesFilter = filter === "All" || item.status === filter;
    const matchesSearch = item.name
      .toLowerCase()
      .includes(search.toLowerCase());
    return matchesFilter && matchesSearch;
  });

  const handleRowClick = (id, status) => {
    navigate(`/user-details/${id}`, { state: { status } }); // Pass the status here
  };

  return (
    <div className="p-6 bg-gray-00">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">EPayEezz Registration Management</h1>
        <button
          className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300"
          onClick={() => console.log("Export functionality")}
        >
          Export
        </button>
      </div>

      {/* Filters and Search */}
      <div className="flex items-center space-x-4 mb-4">
        {/* Filter Dropdown */}
        <div className="relative filter-dropdown">
          <button
            className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300"
            onClick={() => setIsFilterOpen(!isFilterOpen)}
          >
            Filters
          </button>
          {isFilterOpen && (
            <div className="absolute mt-2 bg-white shadow rounded w-40">
              {["All", "Verified", "Pending", "Rejected"].map((status) => (
                <div
                  key={status}
                  className={`px-4 py-2 cursor-pointer ${
                    filter === status ? "bg-gray-100 font-bold" : ""
                  }`}
                  onClick={() => {
                    setFilter(status);
                    setIsFilterOpen(false);
                  }}
                >
                  {status}
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Search Box */}
        <input
          type="text"
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="px-4 py-2 border rounded w-64"
        />
      </div>

      {/* Table */}
      <div className="bg-white shadow rounded border ">
        <table className="w-full table-auto">
          <thead>
            <tr className="border border-separate">
              <th className="px-4 py-2 text-center">User ID</th>
              <th className="px-4 py-2 text-center">User Full Name</th>
              <th className="px-4 py-2 text-center">CAN Number</th>
              <th className="px-4 py-2 text-center">Status</th>
              <th className="px-4 py-2 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr
                key={index}
                className="cursor-pointer hover:bg-gray-100"
                onClick={() => handleRowClick(item.id, item.status)} // Pass both ID and status
              >
                <td className="px-6 py-5 text-center">{item.id}</td>
                <td className="px-6 py-5 text-center">{item.name}</td>
                <td className="px-6 py-5 text-center">{item.can}</td>
                <td className="px-6 py-5 text-center">
                  <span
                    className={`px-2 py-1 rounded-full text-white ${
                      item.status === "Verified"
                        ? "bg-green-500"
                        : item.status === "Pending"
                        ? "bg-yellow-500"
                        : "bg-red-500"
                    }`}
                  >
                    {item.status}
                  </span>
                </td>
                <td className="px-4 py-2">
                  <button className="text-blue-500 hover:underline">
                    Review
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EpayeezzManagement;
