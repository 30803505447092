import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEdit, FaTrash, FaPlus, FaChevronLeft, } from "react-icons/fa";

const RiskProfiles = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([
    { id: 1, name: "Low Risk", description: "Minimal risk involved" },
    { id: 2, name: "Moderate Risk", description: "Some risk but manageable" },
    { id: 3, name: "High Risk", description: "Significant risk involved" },
  ]);

  const deleteCategory = (id) => {
    setCategories(categories.filter((category) => category.id !== id));
  };

  return (
    <div className="p-8">
      <div className="flex items-center justify-between mb-8">
        <button
          className="mb-4 text-2xl font-bold text-[#000000] flex items-center"
          onClick={() => navigate(-1)}
        >
          <FaChevronLeft className="mr-2" size={20}/> Risk Profiles
        </button>

        <button
          className="flex items-center bg-[#FF9800] text-gray-800 px-4 py-2 rounded-md mb-4"
          onClick={() => navigate("/add-category")}
        >
          <FaPlus className="mr-2" /> Add New Category
        </button>
      </div>
      <table className="w-full bg-white shadow-md rounded-lg">
        <thead className="bg-gray-100">
          <tr>
            <th className="p-4 text-center">S No.</th>
            <th className="p-4 text-center">Category Name</th>
            <th className="p-4 text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category, index) => (
            <tr key={category.id} className="border-b">
              <td className="p-4 text-center">{index + 1}</td>
              <td className="p-4 text-center">{category.name}</td>
              <td className="p-4 items-center flex justify-center space-x-2">
                <button
                  className="bg-yellow-500 text-white px-2 py-1 rounded-md"
                  onClick={() => navigate(`/edit-category/${category.id}`)}
                >
                  <FaEdit />
                </button>
                <button
                  className="bg-red-500 text-white px-2 py-1 rounded-md"
                  onClick={() => deleteCategory(category.id)}
                >
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RiskProfiles;
