export const mockBanners = [
    { id: 1, image: "https://via.placeholder.com/600x200", title: "Banner 1" },
    { id: 2, image: "https://via.placeholder.com/600x200", title: "Banner 2" },
    { id: 3, image: "https://via.placeholder.com/600x200", title: "Banner 3" },
  ];
  
  export const mockFAQCategories = [
    { id: 1, name: "Most Asked", totalQuestions: 10 },
    { id: 2, name: "My Profile & KYC", totalQuestions: 7 },
    { id: 3, name: "Payments & Withdrawals", totalQuestions: 5 },
    { id: 4, name: "Mutual Funds", totalQuestions: 8 },
  ];
  
  export const mockFAQs = {
    "Most Asked": [
      { question: "What is Lorem Ipsum?", answer: "Lorem Ipsum is dummy text." },
      { question: "Why use Lorem Ipsum?", answer: "It makes text look natural." },
    ],
    "My Profile & KYC": [
      { question: "How to update KYC?", answer: "Go to the profile section." },
    ],
    "Payments & Withdrawals": [
      {
        question: "How to withdraw money?",
        answer: "Use the withdrawal section under payments.",
      },
    ],
    "Mutual Funds": [
      {
        question: "What is a mutual fund?",
        answer: "A mutual fund pools money from investors.",
      },
    ],
  };
  