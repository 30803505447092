import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HiOutlineCube, HiOutlineTrendingUp } from "react-icons/hi";
import { FaFilter, FaSearch } from "react-icons/fa";

const MutualFundsManagement = () => {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("All"); // Default filter
  const [showFilterOptions, setShowFilterOptions] = useState(true); // Filter dropdown open by default

  const filterOptions = ["All", "Mid Cap", "Large Cap", "Small Cap"];

  const handleSearchChange = (e) => setSearchTerm(e.target.value);

  const handleFilterChange = (option) => {
    setSelectedFilter(option);
    setShowFilterOptions(false); // Close filter dropdown after selecting an option
  };

  const mockData = [
    {
      id: 1,
      name: "Axis Bluechip Fund Direct Plan Growth",
      type: "Equity",
      category: "Mid Cap",
      clients: "+50",
    },
    {
      id: 2,
      name: "Lorem Ipsum is a dummy text",
      type: "Equity",
      category: "Small Cap",
      clients: "+73",
    },
    {
      id: 3,
      name: "Lorem Ipsum is a dummy text",
      type: "Equity",
      category: "Large Cap",
      clients: "+103",
    },
    {
      id: 4,
      name: "Lorem Ipsum is a dummy text",
      type: "Equity",
      category: "Large Cap",
      clients: "+73",
    },
    {
      id: 5,
      name: "Lorem Ipsum is a dummy text",
      type: "Equity",
      category: "Large Cap",
      clients: "+5K",
    },
  ];

  const filteredData = mockData.filter(
    (fund) =>
      (selectedFilter === "All" || fund.category === selectedFilter) &&
      fund.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-6 bg-gray-100">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Mutual Funds Management</h1>
        <div className="flex items-center space-x-4">
          <button
            className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600"
            onClick={() => navigate("/trending-funds")}
          >
            Edit Trending Funds
          </button>
          <button
            className="bg-black text-white px-4 py-2 rounded hover:bg-gray-800"
            onClick={() => navigate("/collection-categories")}
          >
            Edit Collection Categories
          </button>
        </div>
      </div>

      {/* Info Cards */}
      <div className="grid grid-cols-2 gap-6 mb-6">
        <div className="bg-white shadow p-4 rounded flex items-center">
          <div className="p-4 bg-orange-100 text-orange-600 rounded-full">
            <HiOutlineCube size={24} />
          </div>
          <div className="ml-4">
            <p className="text-sm text-gray-500">Total Mutual Funds</p>
            <p className="text-xl font-bold">1093</p>
          </div>
        </div>
        <div className="bg-white shadow p-4 rounded flex items-center">
          <div className="p-4 bg-green-100 text-green-600 rounded-full">
            <HiOutlineTrendingUp size={24} />
          </div>
          <div className="ml-4">
            <p className="text-sm text-gray-500">Trending Funds</p>
            <p className="text-xl font-bold">103</p>
          </div>
        </div>
      </div>

      {/* Filters and Search */}
      <div className="flex justify-between items-center mb-6 relative">
        {/* Filter Dropdown */}
        <div className="relative">
          <button
            className="bg-gray-200 px-4 py-2 rounded flex items-center space-x-2 hover:bg-gray-300"
            onClick={() => setShowFilterOptions(!showFilterOptions)}
          >
            <FaFilter />
            <span>{selectedFilter}</span>
          </button>
          {showFilterOptions && (
            <div className="absolute top-12 left-0 bg-white shadow rounded w-48">
              {filterOptions.map((option) => (
                <div
                  key={option}
                  className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${
                    selectedFilter === option ? "text-orange-500 font-bold" : ""
                  }`}
                  onClick={() => handleFilterChange(option)}
                >
                  {option}
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Search Box */}
        <div className="flex items-center space-x-2 border px-4 py-2 rounded bg-white">
          <FaSearch />
          <input
            type="text"
            placeholder="Search Mutual Funds"
            className="outline-none"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      {/* Mutual Funds Table */}
      <div className="bg-white shadow rounded overflow-hidden">
        <table className="w-full border-collapse">
          <thead className="bg-gray-100 border-b">
            <tr>
              <th className="px-4 py-4 border-r border-gray-200 text-center">S.No</th>
              <th className="px-4 py-4 border-r border-gray-200 text-center">
                Mutual Fund Name
              </th>
              <th className="px-4 py-4 border-r border-gray-200 text-center">Type</th>
              <th className="px-4 py-4 border-r border-gray-200 text-center">
                Category
              </th>
              <th className="px-4 py-4 border-r border-gray-200 text-center">
                Clients
              </th>
              <th className="px-4 py-4 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((fund, index) => (
              <tr
                key={fund.id}
                className="hover:bg-gray-50 border-b border-gray-200"
              >
                <td className="px-4 py-6 text-center">{index + 1}</td>
                <td className="px-4 py-6 text-center">{fund.name}</td>
                <td className="px-4 py-6 text-center">{fund.type}</td>
                <td className="px-4 py-6 text-center">{fund.category}</td>
                <td className="px-4 py-6 text-center">{fund.clients}</td>
                <td className="px-4 py-6 text-orange-500 text-center cursor-pointer hover:underline">
                  Review
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-center mt-6">
        <button className="px-4 py-2 mx-1 rounded border bg-gray-200 hover:bg-gray-300">
          1
        </button>
        <button className="px-4 py-2 mx-1 rounded border bg-gray-200 hover:bg-gray-300">
          2
        </button>
        <button className="px-4 py-2 mx-1 rounded border bg-gray-200 hover:bg-gray-300">
          3
        </button>
      </div>
    </div>
  );
};

export default MutualFundsManagement;
