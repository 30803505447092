import React from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";

const UserDetailForm = () => {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
  
    // Get the status from the location state
    const { status } = location.state || { status: "Pending" }; // Default to "Pending" if no status is passed
  
    // Debugging logs
    console.log("Location State:", location.state);
    console.log("Status Received:", status);

  return (
    <div className="p-6 bg-gray-100">
      {/* Back Button */}
      <button
        className="mb-6 bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 flex items-center"
        onClick={() => navigate(-1)}
      >
        ← Back
      </button>

      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">User Full Name</h1>
        <div
          className={`px-4 py-2 rounded text-white ${
            status === "Verified"
              ? "bg-green-500"
              : status === "Pending"
              ? "bg-yellow-500"
              : "bg-red-500"
          }`}
        >
          {status}
        </div>
      </div>

      {/* User Details */}
      <div className="bg-white shadow rounded p-6 grid grid-cols-2 gap-6">
        <div>
          <label className="block text-gray-700 font-medium mb-2">User ID</label>
          <input
            type="text"
            value={id}
            readOnly
            className="w-full px-4 py-2 border rounded"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-medium mb-2">ARN Name</label>
          <input
            type="text"
            value="Sachin Kumar Bhardwaj"
            readOnly
            className="w-full px-4 py-2 border rounded"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-medium mb-2">ARN Code</label>
          <input
            type="text"
            value="ARN-287423"
            readOnly
            className="w-full px-4 py-2 border rounded"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-medium mb-2">
            Sub Brk. ARN Code
          </label>
          <input
            type="text"
            placeholder="Enter Sub Brk. ARN Code"
            className="w-full px-4 py-2 border rounded"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-medium mb-2">
            Sub Brk. Code
          </label>
          <input
            type="text"
            placeholder="Enter Sub Brk. Code"
            className="w-full px-4 py-2 border rounded"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-medium mb-2">
            Sub Brk. Name
          </label>
          <input
            type="text"
            placeholder="Enter Sub Brk. Name"
            className="w-full px-4 py-2 border rounded"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-medium mb-2">
            Branch / RM Code
          </label>
          <input
            type="text"
            placeholder="Enter Branch / RM Code"
            className="w-full px-4 py-2 border rounded"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-medium mb-2">EUIN Code</label>
          <input
            type="text"
            placeholder="Enter EUIN Code"
            className="w-full px-4 py-2 border rounded"
          />
        </div>
      </div>
    </div>
  );
};

export default UserDetailForm;
