// src/components/CANManagement.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaFilter, FaChevronLeft, FaChevronRight } from 'react-icons/fa';

// Dummy User Data
const usersData = [
  { name: 'John Doe', email: 'john@example.com', phone: '1234567890', holdingNature: 'Individual', status: 'Pending' },
  { name: 'Jane Smith', email: 'jane@example.com', phone: '0987654321', holdingNature: 'Joint', status: 'Verified' },
  { name: 'Alice Johnson', email: 'alice@example.com', phone: '1122334455', holdingNature: 'Individual', status: 'Pending' },
  { name: 'Bob Brown', email: 'bob@example.com', phone: '6677889900', holdingNature: 'Corporate', status: 'New' },
  { name: 'Charlie Clark', email: 'charlie@example.com', phone: '4455667788', holdingNature: 'Individual', status: 'Verified' },
  { name: 'Eve Adams', email: 'eve@example.com', phone: '5566778899', holdingNature: 'Corporate', status: 'New' },
  { name: 'Frank Miller', email: 'frank@example.com', phone: '2233445566', holdingNature: 'Joint', status: 'Verified' },
  // Add more users here for pagination testing
];

const CANManagement = () => {
  const [users, setUsers] = useState(usersData);
  const [filteredUsers, setFilteredUsers] = useState(usersData);
  const [searchTerm, setSearchTerm] = useState('');
  const [holdingFilter, setHoldingFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 5;

  const navigate = useNavigate();

  // Handle Row Click to Navigate to User Detail Page
  const handleRowClick = (name) => {
    navigate(`/user-detail/${name}`);
  };

  // Filter Logic
  useEffect(() => {
    let filtered = usersData;

    if (holdingFilter) {
      filtered = filtered.filter(user => user.holdingNature === holdingFilter);
    }
    if (statusFilter) {
      filtered = filtered.filter(user => user.status === statusFilter);
    }
    if (searchTerm) {
      filtered = filtered.filter(
        user =>
          user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.phone.includes(searchTerm)
      );
    }

    setFilteredUsers(filtered);
  }, [holdingFilter, statusFilter, searchTerm]);

  // Pagination Logic
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(prev => prev + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(prev => prev - 1);
  };

  // Status Color Function
  const getStatusColor = (status) => {
    switch (status) {
      case 'Verified':
        return 'bg-green-100';
      case 'Pending':
        return 'bg-yellow-100';
      case 'New':
        return 'bg-blue-100';
      default:
        return 'bg-gray-100';
    }
  };

  return (
    <div className="container mx-auto p-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">CAN Management System</h1>
        <div className="flex space-x-4">
          <input
            type="text"
            placeholder="Search..."
            className="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-md flex items-center"
            onClick={() => setHoldingFilter('Individual')}
          >
            <FaFilter className="mr-2" /> Filter Individual
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-md flex items-center"
            onClick={() => setStatusFilter('Verified')}
          >
            <FaFilter className="mr-2" /> Filter Verified
          </button>
        </div>
      </div>

      {/* Stats Boxes */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
        <StatBox title="Total CAN Registrations" count={usersData.length} color="bg-blue-100" icon="📋" />
        <StatBox title="New Registrations" count={usersData.filter(user => user.status === 'New').length} color="bg-green-100" icon="🆕" />
        <StatBox title="Pending Registrations" count={usersData.filter(user => user.status === 'Pending').length} color="bg-yellow-100" icon="⏳" />
        <StatBox title="Verified Registrations" count={usersData.filter(user => user.status === 'Verified').length} color="bg-purple-100" icon="✔️" />
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 rounded-lg">
          <thead className="bg-gray-100">
            <tr>
              <th className="text-left p-4">User Full Name</th>
              <th className="text-left p-4">Email / Mobile no.</th>
              <th className="text-left p-4">Holding Nature</th>
              <th className="text-left p-4">Status</th>
              <th className="text-left p-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentUsers.map((user, idx) => (
              <tr key={idx} className="cursor-pointer hover:bg-gray-100" onClick={() => handleRowClick(user.name)}>
                <td className="p-4">{user.name}</td>
                <td className="p-4">{user.email} / {user.phone}</td>
                <td className="p-4">{user.holdingNature}</td>
                <td className={`p-4 ${getStatusColor(user.status)}`}>{user.status}</td>
                <td className="p-4">
                  <button className="bg-blue-500 text-white px-4 py-2 rounded-md">Review Test</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-center items-center mt-6">
        <button className="p-2 rounded-full bg-gray-200 hover:bg-gray-300" onClick={prevPage}>
          <FaChevronLeft />
        </button>
        <div className="mx-2 flex space-x-2">
          {[...Array(totalPages)].map((_, idx) => (
            <button
              key={idx}
              className={`p-2 rounded-full ${currentPage === idx + 1 ? 'bg-black text-white' : 'bg-white text-black border'}`}
              onClick={() => setCurrentPage(idx + 1)}
            >
              {idx + 1}
            </button>
          ))}
        </div>
        <button className="p-2 rounded-full bg-gray-200 hover:bg-gray-300" onClick={nextPage}>
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
};

// StatBox Component
const StatBox = ({ title, count, color, icon }) => (
  <div className={`p-6 rounded-lg ${color} flex items-center space-x-4`}>
    <span className="text-4xl">{icon}</span>
    <div>
      <h2 className="text-xl font-bold">{title}</h2>
      <p className="text-2xl">{count}</p>
    </div>
  </div>
);

export default CANManagement;
