import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AddNewCategory = () => {
  const navigate = useNavigate();

  // States
  const [categoryName, setCategoryName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);

  // Handle Image Upload
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.size <= 1 * 1024 * 1024) {
      // Ensure file size is under 1MB
      setImage(URL.createObjectURL(file));
    } else {
      alert("File size should not exceed 1MB");
    }
  };

  // Handle Save
  const handleSave = () => {
    if (!categoryName || !description || !image) {
      alert("Please fill all the fields and upload an image.");
      return;
    }
    const newCategory = { categoryName, description, image };
    console.log("New Category Data:", newCategory);
    // Add API call here for saving
    alert("Category saved successfully!");
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      {/* Back Button */}
      <button
        className="mb-6 bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 flex items-center"
        onClick={() => navigate(-1)}
      >
        ← Back
      </button>

      {/* Header */}
      <h1 className="text-2xl font-bold mb-6">Add New Collection Category</h1>

      {/* Form */}
      <div className="bg-white p-6 shadow rounded">
        {/* Category Name */}
        <div className="mb-6">
          <label htmlFor="categoryName" className="block text-gray-700 mb-2">
            Category Name
          </label>
          <input
            id="categoryName"
            type="text"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            placeholder="Enter Here"
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-orange-500"
          />
        </div>

        {/* Description */}
        <div className="mb-6">
          <label htmlFor="description" className="block text-gray-700 mb-2">
            Description
          </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Write short description"
            rows="4"
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-orange-500"
          ></textarea>
        </div>

        {/* Image Upload */}
        <div className="mb-6">
          <label className="block text-gray-700 mb-2">Add Picture</label>
          <div className="border-dashed border-2 border-orange-500 p-4 rounded flex justify-center items-center">
            {image ? (
              <div className="relative">
                <img
                  src={image}
                  alt="Uploaded"
                  className="w-64 h-64 object-cover rounded"
                />
                <button
                  className="absolute top-0 right-0 bg-red-500 text-white px-2 py-1 rounded-full text-sm hover:bg-red-600"
                  onClick={() => setImage(null)}
                >
                  Remove
                </button>
              </div>
            ) : (
              <label
                htmlFor="imageUpload"
                className="cursor-pointer text-orange-500 flex flex-col items-center"
              >
                <span>+ Add Picture</span>
                <span className="text-sm text-gray-500">
                  Note: Image should not be larger than 1MB (250*450)
                </span>
                <input
                  id="imageUpload"
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="hidden"
                />
              </label>
            )}
          </div>
        </div>

        {/* Buttons */}
        <div className="flex justify-between">
          <button
            className="bg-black text-white px-6 py-2 rounded hover:bg-gray-800"
            onClick={() => navigate(-1)}
          >
            Discard
          </button>
          <button
            className="bg-orange-500 text-white px-6 py-2 rounded hover:bg-orange-600"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddNewCategory;
