import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa'; // Add this line

const AddCategory = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Used for editing an existing category
  const [categoryName, setCategoryName] = useState('');
  const [description, setDescription] = useState('');

  const handleSave = () => {
    console.log('Category saved:', { categoryName, description });
    navigate(-1); // Go back to the previous page
  };

  return (
    <div className="p-8">
      <button
        className="mb-4 text-blue-500 flex items-center"
        onClick={() => navigate(-1)}
      >
        <FaArrowLeft className="mr-2" /> Back
      </button>

      <h1 className="text-2xl font-bold mb-6">
        {id ? 'Edit Category' : 'Add New Category'}
      </h1>

      <div className="mb-4">
        <label className="block text-lg">Category Name</label>
        <input
          type="text"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          className="w-full border p-2 rounded-md"
        />
      </div>

      <div className="mb-4">
        <label className="block text-lg">Description</label>
        <input
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="w-full border p-2 rounded-md"
        />
      </div>

      <div className="flex space-x-4">
        <button
          onClick={() => navigate(-1)}
          className="bg-gray-500 text-white px-4 py-2 rounded-md"
        >
          Discard
        </button>
        <button
          onClick={handleSave}
          className="bg-green-500 text-white px-4 py-2 rounded-md"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddCategory;
