import React from "react";
import { useNavigate } from "react-router-dom";

const AddRole = () => {
  const navigate = useNavigate();

  return (
    <div className="p-6">
      {/* Back Button */}
      <button
        className="text-gray-700 font-normal hover:underline mb-6"
        onClick={() => navigate("/role-management")}
      >
        &larr; Back
      </button>

      <h1 className="text-2xl font-bold mb-6">Add New Role</h1>

      {/* Role Name Input */}
      <div className="bg-white shadow rounded p-6">
        <div className="mb-6">
          <label className="block text-sm font-bold mb-2">Role Name</label>
          <input
            type="text"
            className="w-full border rounded px-4 py-2 focus:outline-none"
            placeholder="Enter Role Name"
          />
        </div>

        {/* Features Section */}
        <h2 className="text-xl font-bold mb-4">Features</h2>
        <div className="flex flex-col space-y-6">
          {/* Feature Dropdown Example */}
          <div>
            <div className="flex justify-between items-center bg-orange-100 px-4 py-2 rounded-md">
              <p className="font-bold">Bulk PDF Export</p>
              <button className="text-orange-600 font-bold">▼</button>
            </div>
            {/* Feature Options */}
            <div className="mt-2 space-y-2">
              <label className="flex items-center space-x-2">
                <input type="radio" name="bulk-pdf" className="form-radio" />
                <span>View (Own)</span>
              </label>
              <label className="flex items-center space-x-2">
                <input type="radio" name="bulk-pdf" className="form-radio" />
                <span>View (Global)</span>
              </label>
              <label className="flex items-center space-x-2">
                <input type="radio" name="bulk-pdf" className="form-radio" />
                <span>Create</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      {/* Login Credentials Button */}
      <div className="mt-6">
        <button
          className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600"
          onClick={() => navigate("/role-management/login-credentials")}
        >
          Login Credentials
        </button>
      </div>

      {/* Save and Discard Buttons */}
      <div className="flex justify-end space-x-4 mt-6">
        <button className="bg-black text-white px-4 py-2 rounded hover:bg-gray-800">
          Discard
        </button>
        <button className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600">
          Save
        </button>
      </div>
    </div>
  );
};

export default AddRole;
