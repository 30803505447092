import React from "react";
import { useNavigate } from "react-router-dom";

const LoginCredentials = () => {
  const navigate = useNavigate();

  return (
    <div className="p-6">
      {/* Back Button */}
      <button
        className="text-gray-700 font-normal hover:underline mb-6"
        onClick={() => navigate(-1)}
      >
        &larr; Back
      </button>

      <h1 className="text-2xl font-bold mb-6">Login Credentials</h1>

      {/* Form Inputs */}
      <div className="bg-white shadow rounded p-6">
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2">Email Address</label>
          <input
            type="email"
            className="w-full border rounded px-4 py-2 focus:outline-none"
            placeholder="Enter Email Address"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2">Password</label>
          <input
            type="password"
            className="w-full border rounded px-4 py-2 focus:outline-none"
            placeholder="Enter Password"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2">
            Re-Enter Password
          </label>
          <input
            type="password"
            className="w-full border rounded px-4 py-2 focus:outline-none"
            placeholder="Re-Enter Password"
          />
        </div>
      </div>

      {/* Save and Discard Buttons */}
      <div className="flex justify-end space-x-4 mt-6">
        <button
          className="bg-black text-white px-4 py-2 rounded hover:bg-gray-800"
          onClick={() => navigate(-1)}
        >
          Discard
        </button>
        <button className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600">
          Save
        </button>
      </div>
    </div>
  );
};

export default LoginCredentials;
