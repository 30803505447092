import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { mockFAQs } from "../utilities/mockContentData";

const FAQDetails = () => {
  const { category } = useParams();
  const navigate = useNavigate();
  const [faqs, setFaqs] = useState(mockFAQs[category]);

  const addFAQ = () => {
    setFaqs([...faqs, { question: "", answer: "" }]);
  };

  const updateFAQ = (index, field, value) => {
    const updatedFAQs = [...faqs];
    updatedFAQs[index][field] = value;
    setFaqs(updatedFAQs);
  };

  const deleteFAQ = (index) => {
    setFaqs(faqs.filter((_, i) => i !== index));
  };

  return (
    <div className="p-6">
           {/* Back Button */}
      <button
        className="text-gray-700 font-normal hover:underline mb-6"
        onClick={() => navigate(-1)} // Navigates back
      >
        &larr; Back
      </button>
      <h1 className="text-2xl font-bold mb-6">{category} FAQs</h1>
      <div className="grid gap-4">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className="bg-white shadow rounded p-4 flex flex-col space-y-2"
          >
            <input
              type="text"
              className="w-full border rounded px-4 py-2"
              placeholder="Enter Question"
              value={faq.question}
              onChange={(e) =>
                updateFAQ(index, "question", e.target.value)
              }
            />
            <textarea
              className="w-full border rounded px-4 py-2"
              placeholder="Enter Answer"
              value={faq.answer}
              onChange={(e) =>
                updateFAQ(index, "answer", e.target.value)
              }
            />
            <div className="flex justify-end space-x-4">
              <button
                className="text-red-500 hover:underline"
                onClick={() => deleteFAQ(index)}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
      <button
        className="bg-orange-100 text-orange-800 px-4 py-2 mt-4 rounded"
        onClick={addFAQ}
      >
        + Add Question
      </button>
      <div className="flex justify-end space-x-4 mt-6">
        <button
          className="bg-black text-white px-4 py-2 rounded"
          onClick={() => navigate("/content/faqs")}
        >
          Discard
        </button>
        <button className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600">
          Save
        </button>
      </div>
    </div>
  );
};

export default FAQDetails;
