import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaCalendarAlt, FaSearch } from "react-icons/fa";
import { mockQueries } from "../utilities/mockQueries";

const HelpSupport = () => {
  const [selectedDate, setSelectedDate] = useState(""); // Date for filtering
  const [searchTerm, setSearchTerm] = useState(""); // Search term
  const [showCalendar, setShowCalendar] = useState(false); // Toggle calendar visibility

  // Filter and Search Logic
  const filteredQueries = mockQueries.filter((query) => {
    const isDateMatch = selectedDate
      ? query.date === selectedDate // Compare with selected date
      : true;

    const isSearchMatch = query.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    return isDateMatch && isSearchMatch;
  });

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Help & Support</h1>
      </div>

      {/* Filters and Search */}
      <div className="flex justify-between items-center mb-6">
        {/* Filter Button with Calendar */}
        <div className="relative">
          <button
            className="bg-gray-200 px-4 py-2 rounded flex items-center space-x-2 hover:bg-gray-300"
            onClick={() => setShowCalendar(!showCalendar)}
          >
            <FaCalendarAlt />
            <span>Filter</span>
          </button>
          {showCalendar && (
            <div className="absolute mt-2">
              <input
                type="date"
                className="border px-4 py-2 rounded bg-white shadow"
                value={selectedDate}
                onChange={(e) => {
                  setSelectedDate(e.target.value);
                  setShowCalendar(false); // Close calendar after selecting a date
                }}
              />
            </div>
          )}
        </div>

        {/* Search Bar */}
        <div className="flex items-center space-x-2 border px-4 py-2 rounded bg-white">
          <FaSearch />
          <input
            type="text"
            placeholder="Search Queries"
            className="outline-none"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {/* Table */}
      <div className="bg-white shadow rounded overflow-hidden">
        <table className="w-full">
          <thead className="bg-gray-100">
            <tr>
              <th className="text-left px-4 py-3 border-b">S.No</th>
              <th className="text-left px-4 py-3 border-b">Query Title</th>
              <th className="text-left px-4 py-3 border-b">Date</th>
              <th className="text-left px-4 py-3 border-b">Status</th>
              <th className="text-left px-4 py-3 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredQueries.length > 0 ? (
              filteredQueries.map((query, index) => (
                <tr key={query.id} className="border-b hover:bg-gray-50">
                  <td className="px-4 py-4">{index + 1}</td>
                  <td className="px-4 py-4">{query.title}</td>
                  <td className="px-4 py-4">{query.date}</td>
                  <td className="px-4 py-4">
                    <span
                      className={`px-3 py-1 rounded-full text-white ${
                        query.status === "Resolved"
                          ? "bg-green-500"
                          : query.status === "Pending"
                          ? "bg-orange-500"
                          : "bg-red-500"
                      }`}
                    >
                      {query.status}
                    </span>
                  </td>
                  <td className="px-4 py-4">
                    <Link
                      to={`/query/${query.id}/${query.status}`}
                      className="text-orange-500 hover:underline"
                    >
                      Review
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="px-4 py-4 text-center" colSpan={5}>
                  No queries found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HelpSupport;
