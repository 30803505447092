import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const mockRoleData = {
  id: 2,
  roleName: "Manager",
  totalUsers: 5,
  features: {
    "Bulk PDF Export": "View (Own)",
    Invoices: "Create",
  },
  staff: ["Lorem Ipsum", "Kavya Singh", "Madhu", "Prashant", "Sumit"],
};

const RoleDetails = () => {
  const navigate = useNavigate();
  const { roleId } = useParams();
  const [role, setRole] = useState(mockRoleData);

  const handleFeatureChange = (feature, value) => {
    setRole((prevRole) => ({
      ...prevRole,
      features: {
        ...prevRole.features,
        [feature]: value,
      },
    }));
  };

  const saveChanges = () => {
    console.log("Updated Role Details:", role);
    navigate("/role-management");
  };

  return (
    <div className="p-6">
      <button
        className="text-gray-700 font-normal hover:underline mb-6"
        onClick={() => navigate("/role-management")}
      >
        &larr; Back
      </button>
      <h1 className="text-2xl font-bold mb-6">{role.roleName}</h1>

      {/* Role Details */}
      <div className="bg-white shadow rounded p-6 mb-6">
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div>
            <label className="block text-sm font-bold mb-2">Role Name</label>
            <input
              type="text"
              className="w-full border rounded px-4 py-2"
              value={role.roleName}
              readOnly
            />
          </div>
          <div>
            <label className="block text-sm font-bold mb-2">Total Users</label>
            <input
              type="text"
              className="w-full border rounded px-4 py-2"
              value={role.totalUsers}
              readOnly
            />
          </div>
        </div>

        {/* Features */}
        <h2 className="text-xl font-bold mb-4">Features</h2>
        {Object.keys(role.features).map((feature, index) => (
          <div key={index} className="mb-4">
            <label className="block text-sm font-bold mb-2">{feature}</label>
            <select
              className="w-full border rounded px-4 py-2"
              value={role.features[feature]}
              onChange={(e) => handleFeatureChange(feature, e.target.value)}
            >
              <option value="View (Own)">View (Own)</option>
              <option value="View (Global)">View (Global)</option>
              <option value="Create">Create</option>
              <option value="Edit">Edit</option>
              <option value="Delete">Delete</option>
            </select>
          </div>
        ))}
      </div>

      {/* Staff Members */}
      <div className="bg-white shadow rounded p-6 mb-6">
        <h2 className="text-xl font-bold mb-4">Staff Members Using This Role</h2>
        <ul>
          {role.staff.map((member, index) => (
            <li key={index} className="mb-2">
              <div className="flex items-center space-x-2">
                <span className="bg-gray-200 px-3 py-1 rounded">{index + 1}</span>
                <p>{member}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            id="update-staff"
            className="w-4 h-4"
          />
          <label htmlFor="update-staff" className="text-sm">
            Update all staff members permissions that are using this role
          </label>
        </div>
      </div>

      <div className="flex justify-end space-x-4 mt-6">
        <button
          className="bg-red-500 text-white px-4 py-2 rounded"
          onClick={() => console.log("Role Deleted")}
        >
          Delete
        </button>
        <button
          className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600"
          onClick={saveChanges}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default RoleDetails;
