import React, { useState } from "react";
import { mockBanners } from "../utilities/mockContentData";
import { useNavigate } from "react-router-dom";

const BannerManagement = () => {
  const navigate = useNavigate();
  const [banners, setBanners] = useState(mockBanners);

  const deleteBanner = (id) => {
    setBanners(banners.filter((banner) => banner.id !== id));
  };

  return (
    <div className="p-6">
      {/* Back Button */}
      <button
        className="text-gray-700 font-normal hover:underline mb-6"
        onClick={() => navigate(-1)} // Navigates back
      >
        &larr; Back
      </button>

      <h1 className="text-2xl font-bold mb-6">Homescreen Banners</h1>
      <button className="bg-black text-white px-4 py-2 rounded mb-4">
        + Add New Banner
      </button>
      <div className="grid gap-4">
        {banners.map((banner) => (
          <div
            key={banner.id}
            className="bg-white shadow rounded overflow-hidden flex items-center justify-between p-4"
          >
            <img
              src={banner.image}
              alt={banner.title}
              className="h-24 w-1/2 object-cover rounded"
            />
            <div className="flex space-x-4">
              <button
                className="text-red-500 hover:underline"
                onClick={() => deleteBanner(banner.id)}
              >
                Delete
              </button>
              <button className="text-orange-500 hover:underline">Edit</button>
            </div>
          </div>
        ))}
      </div>
      <button className="bg-orange-500 text-white px-4 py-2 mt-6 rounded hover:bg-orange-600">
        Save Changes
      </button>
    </div>
  );
};

export default BannerManagement;
