import React from "react";
import { useNavigate } from "react-router-dom";

const mockRoles = [
  { id: 1, roleName: "Accounts", totalUsers: 1 },
  { id: 2, roleName: "Manager", totalUsers: 3 },
  { id: 3, roleName: "Contractor", totalUsers: 5 },
  { id: 4, roleName: "Lorem Ipsum", totalUsers: 2 },
  { id: 5, roleName: "Lorem Ipsum", totalUsers: 10 },
];

const RoleManagement = () => {
  const navigate = useNavigate();

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Role Management</h1>
      <div className="bg-white shadow p-4 border w-fit rounded mb-6 flex items-center">
        <div className="p-4 w-10 h-10 flex items-center justify-center bg-orange-100 text-orange-600 rounded-full">
          <span>👤</span>
        </div>
        <div className="ml-4">
          <p className="text-sm font-semibold text-gray-500">Total Roles</p>
          <p className="text-xl font-bold">12</p>
        </div>
      </div>

      <div className="flex justify-between items-center mb-6">
        <button
          className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600"
          onClick={() => navigate("/role-management/add")}
        >
          + Add New Role
        </button>
        <div className="flex items-center space-x-4">
          <button className="bg-gray-200 px-4 py-2 rounded">Export</button>
          <input
            type="text"
            placeholder="Search"
            className="border rounded px-4 py-2"
          />
        </div>
      </div>

      <div className="bg-white shadow rounded overflow-hidden">
        <table className="w-full">
          <thead className="bg-gray-100">
            <tr>
              <th className="text-left px-4 py-2 border-b">S.No</th>
              <th className="text-left px-4 py-2 border-b">Role Name</th>
              <th className="text-left px-4 py-2 border-b">Total Users</th>
              <th className="text-left px-4 py-2 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {mockRoles.map((role, index) => (
              <tr key={role.id} className="border-b">
                <td className="px-4 py-4">{index + 1}</td>
                <td className="px-4 py-4">{role.roleName}</td>
                <td className="px-4 py-4">{role.totalUsers}</td>
                <td
                  className="px-4 py-4 text-orange-500 cursor-pointer hover:underline"
                  onClick={() => navigate(`/role-management/${role.id}`)}
                >
                  Review
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RoleManagement;
