import React from "react";
import { useNavigate } from "react-router-dom";
import { mockFAQCategories } from "../utilities/mockContentData";

const FAQCategories = () => {
  const navigate = useNavigate();

  return (
    <div className="p-6">
      {/* Back Button */}
      <button
        className="text-gray-700 font-normal hover:underline mb-6"
        onClick={() => navigate(-1)} // Navigates back
      >
        &larr; Back
      </button>
      <h1 className="text-2xl font-bold mb-6">FAQs Categories</h1>
      <button
        className="bg-black text-white px-4 py-2 rounded mb-4"
        onClick={() => navigate("/content/faqs/add")}
      >
        + Add New Category
      </button>
      <div className="grid gap-4">
        {mockFAQCategories.map((category) => (
          <button
            key={category.id}
            className="bg-orange-100 p-4 rounded shadow flex justify-between items-center"
            onClick={() => navigate(`/content/faqs/${category.name}`)}
          >
            <h2 className="font-bold">{category.name}</h2>
            <span className="text-orange-800">&rarr;</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default FAQCategories;
