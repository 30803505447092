import React, { useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const AddTrendingFund = () => {
  const navigate = useNavigate(); // Navigation hook for the back button
  const [selectedFunds, setSelectedFunds] = useState([
    "Axis Bluechip Fund Direct Plan Growth | Equity | Mid Cap | Very High Risk",
  ]);
  const [newFund, setNewFund] = useState("");

  // Handle adding a new fund
  const handleAddFund = () => {
    if (newFund.trim()) {
      setSelectedFunds([...selectedFunds, newFund.trim()]);
      setNewFund(""); // Clear input
    }
  };

  // Handle removing a fund
  const handleRemoveFund = (index) => {
    const updatedFunds = selectedFunds.filter((_, i) => i !== index);
    setSelectedFunds(updatedFunds);
  };

  // Handle form submission (save)
  const handleSave = () => {
    console.log("Saved Funds:", selectedFunds);
    // Add API call or functionality here for saving
    alert("Funds saved successfully!");
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className="p-6 bg-gray-100">
      {/* Back Button */}
      <button
        className="mb-6 bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 flex items-center"
        onClick={() => navigate(-1)}
      >
        ← Back
      </button>

      {/* Header */}
      <h1 className="text-2xl font-bold mb-6">Add Fund to Trending Funds</h1>

      {/* Form */}
      <div className="bg-white shadow rounded p-6">
        {/* Select Fund Input */}
        <div className="mb-6">
          <label className="block text-gray-700 font-medium mb-2">
            Mutual Fund Name
          </label>
          <select
            className="w-full px-4 py-2 border rounded"
            value={newFund}
            onChange={(e) => setNewFund(e.target.value)}
          >
            <option value="" disabled>
              Select
            </option>
            <option value="Axis Bluechip Fund Direct Plan Growth | Equity | Mid Cap | Very High Risk">
              Axis Bluechip Fund Direct Plan Growth | Equity | Mid Cap | Very
              High Risk
            </option>
            <option value="Lorem Ipsum Fund | Equity | Small Cap | High Risk">
              Lorem Ipsum Fund | Equity | Small Cap | High Risk
            </option>
            <option value="Another Fund Example | Debt | Large Cap | Medium Risk">
              Another Fund Example | Debt | Large Cap | Medium Risk
            </option>
          </select>
        </div>

        {/* Selected Funds List */}
        {selectedFunds.map((fund, index) => (
          <div
            key={index}
            className="flex justify-between items-center bg-gray-50 px-4 py-2 mb-4 rounded"
          >
            <span>{fund}</span>
            <button
              className="text-red-500 hover:text-red-700"
              onClick={() => handleRemoveFund(index)}
            >
              <FaTrashAlt />
            </button>
          </div>
        ))}

        {/* Save Button */}
        <button
          className="bg-orange-500 text-white px-6 py-2 rounded hover:bg-orange-600"
          onClick={handleSave}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddTrendingFund;
