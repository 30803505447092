import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { mockQueries } from "../utilities/mockQueries";
import { FaAngleLeft } from "react-icons/fa";

const QueryDetail = () => {
  const { id, status } = useParams(); // Retrieve ID and status from URL params
  const navigate = useNavigate();

  // Find the query based on the ID
  const query = mockQueries.find((query) => query.id === parseInt(id));

  if (!query) {
    return (
      <div className="p-6">
        <button
          className="text-gray-700 font-normal hover:underline mb-4"
          onClick={() => navigate(-1)}
        >
          &larr;
        </button>
        <h1 className="text-xl font-bold">Query not found</h1>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-2">
          <button
            className="text-gray-700 font-semibold"
            onClick={() => navigate(-1)}
          >
            <FaAngleLeft size={24}/>
          </button>
          <h1 className="text-2xl font-bold">{query.title}</h1>
        </div>
        {/* Status Badge and Export Button */}
        <div className="flex gap-4 items-center mb-6">
        <button className="bg-gray-400 text-white px-4 py-2 rounded hover:bg-blue-600">
            Export
          </button>
          <span
            className={`px-3 py-1 rounded text-white ${
              status === "Resolved"
                ? "bg-green-500"
                : status === "Pending"
                ? "bg-orange-500"
                : "bg-red-500"
            }`}
          >
            {status}
          </span>
       
        </div>
      </div>

      {/* Query Details */}
      <div className="bg-white shadow rounded p-6 mb-6">
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div>
            <label className="block text-sm font-bold mb-2">
              Title of Query
            </label>
            <input
              type="text"
              className="w-full border rounded px-4 py-2 bg-gray-100"
              value={query.title}
              readOnly
            />
          </div>
          <div>
            <label className="block text-sm font-bold mb-2">Ticket ID</label>
            <input
              type="text"
              className="w-full border rounded px-4 py-2 bg-gray-100"
              value={query.ticketId}
              readOnly
            />
          </div>
          <div>
            <label className="block text-sm font-bold mb-2">Date</label>
            <input
              type="text"
              className="w-full border rounded px-4 py-2 bg-gray-100"
              value={query.date}
              readOnly
            />
          </div>
          <div>
            <label className="block text-sm font-bold mb-2">
              User Full Name
            </label>
            <input
              type="text"
              className="w-full border rounded px-4 py-2 bg-gray-100"
              value={query.user.fullName}
              readOnly
            />
          </div>
          <div>
            <label className="block text-sm font-bold mb-2">
              User Mobile No.
            </label>
            <input
              type="text"
              className="w-full border rounded px-4 py-2 bg-gray-100"
              value={query.user.mobile}
              readOnly
            />
          </div>
          <div>
            <label className="block text-sm font-bold mb-2">User Email</label>
            <input
              type="text"
              className="w-full border rounded px-4 py-2 bg-gray-100"
              value={query.user.email}
              readOnly
            />
          </div>
        </div>

        {/* Messages */}
        <div className="mb-6">
          {query.messages.map((msg, index) => (
            <div
              key={index}
              className={`mb-4 p-4 rounded ${
                msg.sender === "user"
                  ? "bg-gray-100 text-black"
                  : "bg-orange-100 text-orange-800"
              }`}
            >
              <p className="font-bold">{msg.name || "You Replied"}</p>
              <p className="text-sm">{msg.time}</p>
              <p>{msg.message}</p>
            </div>
          ))}
        </div>

        {/* Reply Box */}
        <div className="flex items-center">
          <input
            type="text"
            placeholder="Type a message"
            className="flex-grow border rounded px-4 py-2"
          />
          <button className="bg-orange-500 text-white px-4 py-2 ml-2 rounded">
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default QueryDetail;
