import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { users } from "../utilities/users";
import { FaFilter } from "react-icons/fa";

const OrderManagement = () => {
  const [filteredStatus, setFilteredStatus] = useState(""); // For filtering based on status
  const [searchTerm, setSearchTerm] = useState(""); // For search functionality
  const [isFilterOpen, setIsFilterOpen] = useState(false); // For dropdown visibility

  const navigate = useNavigate();

  // Filtered and Searched Data
  const filteredUsers = users.filter(
    (user) =>
      (!filteredStatus || user.status === filteredStatus) &&
      (!searchTerm || user.name.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div className="p-6 bg-gray-100">
      {/* Header */}
      <h1 className="text-2xl font-bold mb-6">Order Management</h1>

      {/* Filters and Search */}
      <div className="flex justify-between items-center mb-4">
        {/* Filter Button */}
        <div className="relative">
          <button
            className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300 inline-flex gap-2 items-center"
            onClick={() => setIsFilterOpen(!isFilterOpen)}
          >
            <FaFilter />
            <span>Filter</span>
          </button>
          {isFilterOpen && (
            <div className="absolute left-0 mt-2 bg-white shadow-lg rounded border border-gray-200 w-48">
              <ul>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    setFilteredStatus(""); // Reset filter
                    setIsFilterOpen(false);
                  }}
                >
                  All
                </li>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    setFilteredStatus("Completed");
                    setIsFilterOpen(false);
                  }}
                >
                  Completed
                </li>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    setFilteredStatus("Pending");
                    setIsFilterOpen(false);
                  }}
                >
                  Pending
                </li>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    setFilteredStatus("Rejected");
                    setIsFilterOpen(false);
                  }}
                >
                  Rejected
                </li>
              </ul>
            </div>
          )}
        </div>

        {/* Search and Export */}
        <div className="flex space-x-4">
          <input
            type="text"
            placeholder="Search by name"
            className="border border-gray-300 rounded px-4 py-2"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300"
            onClick={() => console.log("Export functionality")}
          >
            Export
          </button>
        </div>
      </div>

      {/* Table */}
      <div className="bg-white shadow rounded">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-100 border-t border-b border-gray-200">
              <th className="px-4 py-4 border-r border-gray-200 text-center">Order ID</th>
              <th className="px-4 py-4 border-r border-gray-200 text-center">Order Type</th>
              <th className="px-4 py-4 border-r border-gray-200 text-center">Placed On</th>
              <th className="px-4 py-4 border-r border-gray-200 text-center">Status</th>
              <th className="px-4 py-4 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user, index) => (
              <tr
                key={index}
                className="hover:bg-gray-50 cursor-pointer border-b border-gray-200"
                onClick={() => navigate(`/orders/${user.orderId}`, { state: user })}
              >
                <td className="px-4 py-6 border-r border-gray-200 text-center">{user.orderId}</td>
                <td className="px-4 py-6 border-r border-gray-200 text-center">{user.type}</td>
                <td className="px-4 py-6 border-r border-gray-200 text-center">{user.date}</td>
                <td className="px-4 py-6 border-r border-gray-200 text-center">
                  <span
                    className={`px-4 py-2 rounded-full ${
                      user.status === "Completed"
                        ? "bg-green-500 text-white"
                        : user.status === "Pending"
                        ? "bg-yellow-500 text-white"
                        : "bg-red-500 text-white"
                    }`}
                  >
                    {user.status}
                  </span>
                </td>
                <td className="px-4 py-6 text-blue-500 text-center">Review</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderManagement;
