export const mockNotifications = [
    {
      id: 1,
      title: "Notification 1",
      date: "10 October, 2024",
      time: "12:00 PM",
      description: "This is the first notification's description.",
      user: "All Users",
    },
    {
      id: 2,
      title: "Notification 2",
      date: "09 October, 2024",
      time: "08:57 PM",
      description: "This is the second notification's description.",
      user: "User 1",
    },
    {
      id: 3,
      title: "Notification 3",
      date: "08 October, 2024",
      time: "06:09 AM",
      description: "This is the third notification's description.",
      user: "User 2",
    },
  ];
  