import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const OrderDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = location.state;

  if (!user) {
    return <div>No user data available</div>;
  }

  return (
    <div className="p-6 bg-gray-100">
      {/* Back Button */}
      <button
        className="mb-6 bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 flex items-center"
        onClick={() => navigate(-1)}
      >
        ← Back
      </button>

      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">{user.name}</h1>
        <div
          className={`px-4 py-2 rounded ${
            user.status === "Completed"
              ? "bg-green-500 text-white"
              : user.status === "Pending"
              ? "bg-yellow-500 text-white"
              : "bg-red-500 text-white"
          }`}
        >
          {user.status}
        </div>
      </div>

      {/* Order Details */}
      <div className="bg-white shadow rounded p-6 grid grid-cols-2 gap-6">
        <div>
          <h2 className="font-bold">User ID</h2>
          <input
            type="text"
            value={user.id}
            readOnly
            className="border border-gray-300 rounded p-2 w-full"
          />
        </div>
        <div>
          <h2 className="font-bold">Order ID</h2>
          <input
            type="text"
            value={user.orderId}
            readOnly
            className="border border-gray-300 rounded p-2 w-full"
          />
        </div>
        <div>
          <h2 className="font-bold">Order Type</h2>
          <input
            type="text"
            value={user.type}
            readOnly
            className="border border-gray-300 rounded p-2 w-full"
          />
        </div>
        <div>
          <h2 className="font-bold">Placed On</h2>
          <input
            type="text"
            value={user.date}
            readOnly
            className="border border-gray-300 rounded p-2 w-full"
          />
        </div>
        <div>
          <h2 className="font-bold">Paid Via</h2>
          <textarea
            value={user.paidVia}
            readOnly
            className="border border-gray-300 rounded p-2 w-full"
          ></textarea>
        </div>
        <div>
          <h2 className="font-bold">Folio No.</h2>
          <input
            type="text"
            value={user.folioNo}
            readOnly
            className="border border-gray-300 rounded p-2 w-full"
          />
        </div>
        <div>
          <h2 className="font-bold">NAV Date</h2>
          <input
            type="text"
            value={user.navDate}
            readOnly
            className="border border-gray-300 rounded p-2 w-full"
          />
        </div>
        <div>
          <h2 className="font-bold">Type</h2>
          <input
            type="text"
            value={user.typeFund}
            readOnly
            className="border border-gray-300 rounded p-2 w-full"
          />
        </div>
        <div>
          <h2 className="font-bold">Category</h2>
          <input
            type="text"
            value={user.category}
            readOnly
            className="border border-gray-300 rounded p-2 w-full"
          />
        </div>
        <div>
          <h2 className="font-bold">Invested Amount</h2>
          <input
            type="text"
            value={user.investedAmount}
            readOnly
            className="border border-gray-300 rounded p-2 w-full"
          />
        </div>
        <div>
          <h2 className="font-bold">Units Allocated</h2>
          <input
            type="text"
            value={user.unitsAllocated}
            readOnly
            className="border border-gray-300 rounded p-2 w-full"
          />
        </div>
      </div>

      {/* Actions */}
      <div className="flex mt-6 space-x-4">
        <button className="bg-red-500 text-white px-6 py-2 rounded hover:bg-red-600">
          Delete
        </button>
        {user.status === "Pending" && (
          <>
            <button className="bg-gray-500 text-white px-6 py-2 rounded hover:bg-gray-600">
              Reject
            </button>
            <button className="bg-orange-500 text-white px-6 py-2 rounded hover:bg-orange-600">
              Accept
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default OrderDetail;
