// mockQueries.js

export const mockQueries = [
    {
      id: 1,
      title: "Lorem Ipsum",
      date: "2024-10-10",
      status: "Resolved",
      user: {
        fullName: "Akshay Singh",
        mobile: "+91-991887766",
        email: "akshaysingh@gmail.com",
      },
      ticketId: "14776853",
      messages: [
        {
          sender: "user",
          name: "Akshay Singh",
          time: "12/05/24, 08:57 PM",
          message:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
        {
          sender: "admin",
          time: "12/05/24, 08:57 PM",
          message:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
      ],
      attachments: ["image1.png", "image2.png"],
    },
    {
      id: 2,
      title: "Dolor Sit Amet",
      date: "2024-10-11",
      status: "Pending",
      user: {
        fullName: "Rahul Sharma",
        mobile: "+91-9988776655",
        email: "rahulsharma@gmail.com",
      },
      ticketId: "14776854",
      messages: [],
      attachments: [],
    },
    {
      id: 3,
      title: "Consectetur Adipiscing",
      date: "2024-10-12",
      status: "Rejected",
      user: {
        fullName: "Sneha Kapoor",
        mobile: "+91-8877665544",
        email: "snehakapoor@gmail.com",
      },
      ticketId: "14776855",
      messages: [],
      attachments: [],
    },
    {
      id: 4,
      title: "Sed Do Eiusmod",
      date: "2024-10-13",
      status: "Resolved",
      user: {
        fullName: "Ankita Mishra",
        mobile: "+91-7766554433",
        email: "ankitamishra@gmail.com",
      },
      ticketId: "14776856",
      messages: [],
      attachments: [],
    },
    {
      id: 5,
      title: "Tempor Incididunt",
      date: "2024-10-14",
      status: "Resolved",
      user: {
        fullName: "Karan Mehta",
        mobile: "+91-6655443322",
        email: "karanmehta@gmail.com",
      },
      ticketId: "14776857",
      messages: [],
      attachments: [],
    },
  ];
  