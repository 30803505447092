import React from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useParams, useNavigate } from "react-router-dom";

const mockUserDetails = [
  {
    id: "SW01",
    fullName: "Akshay Singh",
    gender: "Male",
    dob: "01/09/1995",
    email: "akshaysingh@gmail.com",
    mobile: "+91-9999999999",
    maritalStatus: "Single",
    panNumber: "ABCDEF000A",
    aadhaarNumber: "1234 5678 9876",
    canNumber: "A23BCDEF000A",
    nominee: {
      fullName: "Hardeep Singh",
      dob: "01/09/1955",
      relationship: "Father",
      panNumber: "ABCDEF000A",
    },
  },
  {
    id: "SW02",
    fullName: "Jane Smith",
    gender: "Female",
    dob: "05/03/1992",
    email: "janesmith@gmail.com",
    mobile: "+91-8888888888",
    maritalStatus: "Married",
    panNumber: "GHIJKL123B",
    aadhaarNumber: "2345 6789 0123",
    canNumber: "B34CDEF123B",
    nominee: {
      fullName: "Emily Smith",
      dob: "12/05/1965",
      relationship: "Mother",
      panNumber: "GHIJKL123B",
    },
  },
];

const UserDetail = () => {
  const { id } = useParams(); // Get user ID from URL params
  const navigate = useNavigate();

  // Find user details based on the ID
  const user = mockUserDetails.find((user) => user.id === id);

  if (!user) {
    return (
      <div className="p-6">
        <button
          className="text-gray-700 font-normal hover:underline mb-4"
          onClick={() => navigate(-1)}
        >
          &larr; Back
        </button>
        <h1 className="text-xl font-bold">User not found</h1>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="flex items-center mb-6">
        <button
          className="text-gray-700 font-normal hover:underline"
          onClick={() => navigate(-1)} // Navigate back
        >
          <FaAngleLeft size={24} />
        </button>
        <h1 className="text-2xl font-bold ">{user.fullName}</h1>
      </div>

      {/* User Details */}
      <section className="flex items-start gap-4">
        <div className="w-4/5 bg-white shadow rounded p-6 mb-6">
          <div className="grid grid-cols-2 gap-4 mb-6">
            <div>
              <label className="block text-sm font-bold mb-2">User ID</label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={user.id}
                readOnly
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-2">Full name</label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={user.fullName}
                readOnly
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-2">Gender</label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={user.gender}
                readOnly
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-2">
                Date of Birth
              </label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={user.dob}
                readOnly
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-2">Mobile no.</label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={user.mobile}
                readOnly
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-2">Email</label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={user.email}
                readOnly
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-2">
                Marital Status
              </label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={user.maritalStatus}
                readOnly
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-2">PAN Number</label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={user.panNumber}
                readOnly
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-2">
                Aadhaar Number
              </label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={user.aadhaarNumber}
                readOnly
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-2">CAN Number</label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={user.canNumber}
                readOnly
              />
            </div>
          </div>

          {/* Nominee Details */}
          <h2 className="text-xl font-bold mb-4">Nominee Details</h2>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-bold mb-2">Full Name</label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={user.nominee.fullName}
                readOnly
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-2">
                Date of Birth
              </label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={user.nominee.dob}
                readOnly
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-2">
                Relationship
              </label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={user.nominee.relationship}
                readOnly
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-2">PAN Number</label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={user.nominee.panNumber}
                readOnly
              />
            </div>
          </div>
        </div>
        {/* Action Buttons */}
        <div className="w-1/5 grid grid-cols-1 gap-4">
          <button
            className="bg-orange-100 text-orange-800 p-4 rounded shadow flex justify-between items-center"
            onClick={() => navigate(`/user/${id}/bank-details`)}
          >
            Linked Bank Details
            <span>&rarr;</span>
          </button>
          <button className="bg-orange-200 text-orange-800 p-4 rounded shadow flex justify-between items-center">
            Order Details
            <span>&rarr;</span>
          </button>
        </div>
      </section>
    </div>
  );
};

export default UserDetail;
