import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaFilter, FaSearch } from "react-icons/fa";

const TrendingFunds = () => {
  const navigate = useNavigate();
  
  // Mock data for trending funds
  const [trendingFunds, setTrendingFunds] = useState([
    { id: 1, name: "Axis Bluechip Fund Direct Plan Growth", category: "Mid Cap", risk: "Low Risk" },
    { id: 2, name: "Lorem Ipsum Fund", category: "Small Cap", risk: "High Risk" },
    { id: 3, name: "Another Fund Example", category: "Large Cap", risk: "Medium Risk" },
  ]);

  // Remove a fund
  const handleRemoveFund = (id) => {
    setTrendingFunds(trendingFunds.filter((fund) => fund.id !== id));
  };

  return (
    <div className="p-6 bg-gray-00">
      {/* Back Button */}
      <button
        className="mb-6 bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 flex items-center"
        onClick={() => navigate(-1)}
      >
        ← Back
      </button>

      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Trending Funds</h1>
        <div className="flex items-center space-x-4">
          <button
            className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300"
            onClick={() => console.log("Export functionality")}
          >
            Export
          </button>
          <button
            className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600"
            onClick={() => navigate("/add-trending-fund")} // Navigate to AddTrendingFund
          >
            + Add Fund
          </button>
        </div>
      </div>

      {/* Filters and Search */}
      <div className="flex justify-between items-center mb-6 relative">
        {/* Filter Button */}
        <div>
          <button className="bg-gray-200 px-4 py-2 rounded flex items-center space-x-2 hover:bg-gray-300">
            <FaFilter />
            <span>Filters</span>
          </button>
        </div>

        {/* Search Bar */}
        <div className="flex items-center space-x-2 border px-4 py-2 rounded bg-white">
          <FaSearch />
          <input
            type="text"
            placeholder="Search Funds"
            className="outline-none"
          />
        </div>
      </div>

      {/* Funds Table */}
      <div className="bg-white shadow rounded overflow-hidden">
        <table className="w-full">
          <thead className="bg-gray-100">
            <tr>
              <th className="text-left px-4 py-2">S.No</th>
              <th className="text-left px-4 py-2">Mutual Fund Name</th>
              <th className="text-left px-4 py-2">Category</th>
              <th className="text-left px-4 py-2">Risk</th>
              <th className="text-left px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {trendingFunds.map((fund, index) => (
              <tr key={fund.id} className="border-b">
                <td className="px-4 py-2">{index + 1}</td>
                <td className="px-4 py-2">{fund.name}</td>
                <td className="px-4 py-2">{fund.category}</td>
                <td className="px-4 py-2">{fund.risk}</td>
                <td className="px-4 py-2 text-orange-500 cursor-pointer hover:underline">
                  <button onClick={() => handleRemoveFund(fund.id)}>Remove</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-center mt-6">
        <button className="px-4 py-2 mx-1 rounded border bg-gray-200 hover:bg-gray-300">
          1
        </button>
        <button className="px-4 py-2 mx-1 rounded border bg-gray-200 hover:bg-gray-300">
          2
        </button>
        <button className="px-4 py-2 mx-1 rounded border bg-gray-200 hover:bg-gray-300">
          3
        </button>
      </div>
    </div>
  );
};

export default TrendingFunds;
