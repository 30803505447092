import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';

const CreateQuestion = () => {
  const navigate = useNavigate();
  const [options, setOptions] = useState(['']);

  const addOption = () => setOptions([...options, '']);
  const removeOption = (index) => setOptions(options.filter((_, i) => i !== index));

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-4">Create New Question</h1>

      <div className="mb-4">
        <label className="block text-lg">Question Serial Number</label>
        <input type="number" className="w-full border p-2 rounded-md" />
      </div>

      <div className="mb-4">
        <label className="block text-lg">Question</label>
        <input type="text" className="w-full border p-2 rounded-md" />
      </div>

      <div className="mb-4">
        <label className="block text-lg">Options</label>
        {options.map((option, index) => (
          <div key={index} className="flex items-center space-x-2 mb-2">
            <input
              type="text"
              value={option}
              onChange={(e) =>
                setOptions(
                  options.map((opt, i) => (i === index ? e.target.value : opt))
                )
              }
              className="w-full border p-2 rounded-md"
            />
            <FaTrash
              className="cursor-pointer text-red-500"
              onClick={() => removeOption(index)}
            />
          </div>
        ))}
        <button
          onClick={addOption}
          className="bg-blue-500 text-white px-4 py-2 rounded-md"
        >
          Add Option
        </button>
      </div>

      <div className="flex space-x-4">
        <button
          onClick={() => navigate(-1)}
          className="bg-gray-500 text-white px-4 py-2 rounded-md"
        >
          Discard
        </button>
        <button
          onClick={() => navigate('/question-detail/1')}
          className="bg-green-500 text-white px-4 py-2 rounded-md"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default CreateQuestion;
