import React, { useState } from "react";
import { FaCalendarAlt, FaSearch } from "react-icons/fa";
import SendNotificationForm from "./SendNotificationForm";
import NotificationDetail from "./NotificationDetail"; // Import detail component
import { mockNotifications } from "../utilities/mockNotifications";

const Notifications = () => {
  const [isNotificationFormOpen, setNotificationFormOpen] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [selectedDate, setSelectedDate] = useState(""); // Date for filtering
  const [showCalendar, setShowCalendar] = useState(false); // Toggle calendar visibility
  const [searchTerm, setSearchTerm] = useState("");
  const [notifications] = useState(mockNotifications);

  const filteredNotifications = notifications.filter((notification) => {
    const isDateMatch = selectedDate
      ? notification.date === selectedDate // Compare with selected date
      : true;

    const isSearchMatch = notification.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    return isDateMatch && isSearchMatch;
  });

  const openNotificationForm = () => setNotificationFormOpen(true);
  const closeNotificationForm = () => setNotificationFormOpen(false);

  return (
    <div className="p-6 bg-gray-100">
      {!isNotificationFormOpen && !selectedNotification ? (
        <>
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold">Notifications</h1>
            <button
              className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600"
              onClick={openNotificationForm}
            >
              Send New Notification
            </button>
          </div>

          <div className="bg-white shadow p-4 border w-fit rounded mb-6 flex items-center">
            <div className="p-4 w-10 h-10 flex items-center justify-center bg-blue-100 text-blue-600 rounded-full">
              <span>🔔</span>
            </div>
            <div className="ml-4">
              <p className="text-sm font-semibold text-gray-500">
                Total Notifications Sent
              </p>
              <p className="text-xl font-bold">40,689</p>
            </div>
          </div>

          <div className="flex justify-between items-center mb-6">
            {/* Filter Button with Calendar */}
            <div className="relative">
              <button
                className="bg-gray-200 px-4 py-2 rounded flex items-center space-x-2 hover:bg-gray-300"
                onClick={() => setShowCalendar(!showCalendar)}
              >
                <FaCalendarAlt />
                <span>Filter</span>
              </button>
              {showCalendar && (
                <div className="absolute mt-2">
                  <input
                    type="date"
                    className="border px-4 py-2 rounded bg-white shadow"
                    value={selectedDate}
                    onChange={(e) => {
                      setSelectedDate(e.target.value);
                      setShowCalendar(false); // Close calendar after selecting a date
                    }}
                  />
                </div>
              )}
            </div>

            {/* Search Bar */}
            <div className="flex items-center space-x-2 border px-4 py-2 rounded bg-white">
              <FaSearch />
              <input
                type="text"
                placeholder="Search Notifications"
                className="outline-none"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <div className="bg-white shadow rounded overflow-hidden">
            <table className="w-full">
              <thead className="bg-gray-100">
                <tr>
                  <th className="text-left px-4 py-3 border-b">S.No</th>
                  <th className="text-left px-4 py-3 border-b">
                    Notification Title
                  </th>
                  <th className="text-left px-4 py-3 border-b">Date</th>
                  <th className="text-left px-4 py-3 border-b">Time</th>
                  <th className="text-left px-4 py-3 border-b">Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredNotifications.length > 0 ? (
                  filteredNotifications.map((notification, index) => (
                    <tr
                      key={notification.id}
                      className="border-b hover:bg-gray-50 cursor-pointer"
                      onClick={() => setSelectedNotification(notification)}
                    >
                      <td className="px-4 py-4">{index + 1}</td>
                      <td className="px-4 py-4">{notification.title}</td>
                      <td className="px-4 py-4">{notification.date}</td>
                      <td className="px-4 py-4">{notification.time}</td>
                      <td className="px-4 py-4 text-orange-500 cursor-pointer hover:underline">
                        Review
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="px-4 py-4 text-center" colSpan={5}>
                      No notifications found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      ) : isNotificationFormOpen ? (
        <SendNotificationForm onClose={closeNotificationForm} />
      ) : (
        <NotificationDetail
          notification={selectedNotification}
          onClose={() => setSelectedNotification(null)}
        />
      )}
    </div>
  );
};

export default Notifications;
