import React from "react";
import { FaAngleLeft } from "react-icons/fa";

const NotificationDetail = ({ notification, onClose }) => {
  if (!notification) return null;

  return (
    <div className="p-6">
      <div className="flex items-center mb-6">
        <button
          className="text-gray-700 font-normal hover:underline"
          onClick={onClose}
        >
          <FaAngleLeft size={28} />
        </button>
        <h1 className="text-2xl font-bold">{notification.title}</h1>
      </div>
      <div className="bg-white shadow rounded p-6">
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2">
            Notification Title
          </label>
          <input
            type="text"
            className="w-full border rounded px-4 py-2 bg-gray-100"
            value={notification.title}
            readOnly
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2">Select User</label>
          <input
            type="text"
            className="w-full border rounded px-4 py-2 bg-gray-100"
            value="All Users"
            readOnly
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2">
            Notification Description
          </label>
          <textarea
            className="w-full border rounded px-4 py-2 bg-gray-100"
            value={notification.description}
            readOnly
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2">
            Notification Push Time & Date
          </label>
          <input
            type="text"
            className="w-full border rounded px-4 py-2 bg-gray-100"
            value={`${notification.date}, ${notification.time}`}
            readOnly
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationDetail;
