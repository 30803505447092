import React from "react";
import { FaAngleLeft } from "react-icons/fa";

const SendNotificationForm = ({ onClose }) => {
  return (
    <div className="p-6">
      <div className="flex items-center mb-6">
        <button
          className="text-gray-700 font-normal hover:underline"
          onClick={onClose}
        >
          <FaAngleLeft size={28} />
        </button>
        <h1 className="text-2xl font-bold">Send New Notification</h1>
      </div>
      <div className="bg-white shadow rounded p-6">
        <form>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Notification Title
            </label>
            <input
              type="text"
              className="w-full border rounded px-4 py-2 focus:outline-none"
              placeholder="Enter Title"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">Select User</label>
            <select className="w-full border rounded px-4 py-2 focus:outline-none">
              <option>Select</option>
              <option>User 1</option>
              <option>User 2</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Notification Description
            </label>
            <textarea
              className="w-full border rounded px-4 py-2 focus:outline-none"
              placeholder="Write description"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-bold mb-2">
              Notification Push Time & Date
            </label>
            <input
              type="datetime-local"
              className="w-full border rounded px-4 py-2 focus:outline-none"
            />
          </div>
          <div className="flex space-x-4">
            <button
              type="button"
              className="bg-black text-white px-4 py-2 rounded"
              onClick={onClose}
            >
              Discard
            </button>
            <button
              type="submit"
              className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600"
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SendNotificationForm;
