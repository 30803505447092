import React from "react";
import { FaBell, FaUserCircle } from "react-icons/fa";

const Navbar = () => {
  const adminName = "John Doe";  // Replace with dynamic name later

  return (
    <div className="navbar flex justify-between items-center h-16 bg-gray-100 px-6 shadow-md w-full">
      <div className="text-xl font-bold">
        Hello, <span className="text-blue-600">{adminName}</span>
      </div>
      <div className="flex items-center space-x-6">
        <FaBell size={24} />
        <div className="flex items-center space-x-6">
          <FaUserCircle size={30} />
          <div>
            <span className="text-lg font-medium">{adminName}</span>
            <div className="text-sm text-gray-500">Admin</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
