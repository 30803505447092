import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AddFAQCategory = () => {
  const navigate = useNavigate();
  const [categoryName, setCategoryName] = useState("");
  const [faqs, setFaqs] = useState([{ question: "", answer: "" }]);

  const addFAQ = () => {
    setFaqs([...faqs, { question: "", answer: "" }]);
  };

  const updateFAQ = (index, field, value) => {
    const updatedFAQs = [...faqs];
    updatedFAQs[index][field] = value;
    setFaqs(updatedFAQs);
  };

  const deleteFAQ = (index) => {
    setFaqs(faqs.filter((_, i) => i !== index));
  };

  const saveCategory = () => {
    if (!categoryName.trim()) {
      alert("Category name cannot be empty!");
      return;
    }
    // Here you can save the category and its FAQs to your backend or mock data
    console.log("Category Name:", categoryName);
    console.log("FAQs:", faqs);
    navigate("/content/faqs"); // Redirect back to FAQ categories
  };

  return (
    <div className="p-6">
      {/* Back Button */}
      <button
        className="text-gray-700 font-normal hover:underline mb-6"
        onClick={() => navigate("/content/faqs")}
      >
        &larr; Back
      </button>

      <h1 className="text-2xl font-bold mb-6">Add New FAQ Category</h1>
      <div className="bg-white shadow rounded p-6">
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2">Category Name</label>
          <input
            type="text"
            className="w-full border rounded px-4 py-2"
            placeholder="Enter category name"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
        </div>

        <h2 className="text-xl font-bold mt-6 mb-4">Questions & Answers</h2>
        <div className="grid gap-4">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="bg-gray-50 p-4 rounded shadow flex flex-col space-y-2"
            >
              <input
                type="text"
                className="w-full border rounded px-4 py-2"
                placeholder="Enter Question"
                value={faq.question}
                onChange={(e) =>
                  updateFAQ(index, "question", e.target.value)
                }
              />
              <textarea
                className="w-full border rounded px-4 py-2"
                placeholder="Enter Answer"
                value={faq.answer}
                onChange={(e) =>
                  updateFAQ(index, "answer", e.target.value)
                }
              />
              <button
                className="text-red-500 hover:underline self-end"
                onClick={() => deleteFAQ(index)}
              >
                Delete
              </button>
            </div>
          ))}
        </div>
        <button
          className="bg-orange-100 text-orange-800 px-4 py-2 mt-4 rounded"
          onClick={addFAQ}
        >
          + Add Question
        </button>
      </div>

      <div className="flex justify-end space-x-4 mt-6">
        <button
          className="bg-black text-white px-4 py-2 rounded"
          onClick={() => navigate("/content/faqs")}
        >
          Discard
        </button>
        <button
          className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600"
          onClick={saveCategory}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddFAQCategory;
