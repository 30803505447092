  import React from "react";
  import { useParams, useNavigate } from "react-router-dom";


  const mockUserBankDetails = [
    {
      id: "SW01",
      primaryBank: {
        accountType: "Saving Account",
        accountNo: "28742354678900",
        ifsc: "287423456",
        autoPay: "Active & Completed",
      },
      secondaryBank: {
        accountType: "Saving Account",
        accountNo: "28742354678900",
        ifsc: "287423456",
      },
      primaryCard: {
        cardHolderName: "Akshay Singh",
        cardNumber: "8742 3546 5678 1234",
        expiryDate: "12/27",
        cvv: "234",
      },
      secondaryCard: {
        cardHolderName: "Akshay Singh",
        cardNumber: "8742 3546 5678 1234",
        expiryDate: "12/27",
        cvv: "234",
      },
      upi: {
        appName: "Google Pay",
        upiId: "akshaysingh2000@okaxis",
      },
    },
    {
      id: "SW02",
      primaryBank: {
        accountType: "Current Account",
        accountNo: "12345678901234",
        ifsc: "123456789",
        autoPay: "Active",
      },
      secondaryBank: {
        accountType: "Saving Account",
        accountNo: "12345678901234",
        ifsc: "123456789",
      },
      primaryCard: {
        cardHolderName: "Jane Smith",
        cardNumber: "1234 5678 9012 3456",
        expiryDate: "11/25",
        cvv: "123",
      },
      secondaryCard: {
        cardHolderName: "Jane Smith",
        cardNumber: "1234 5678 9012 3456",
        expiryDate: "11/25",
        cvv: "123",
      },
      upi: {
        appName: "PhonePe",
        upiId: "janesmith@upi",
      },
    },
  ];
  
  const UserBankDetails = () => {
    const { id } = useParams(); // Get user ID from URL params
    const navigate = useNavigate();
  
    // Find user bank details based on the ID
    const userBankDetails = mockUserBankDetails.find(
      (user) => user.id === id
    );
  
    if (!userBankDetails) {
      return (
        <div className="p-6">
          <button
            className="text-gray-700 font-normal hover:underline mb-4"
            onClick={() => navigate(-1)}
          >
            &larr; Back
          </button>
          <h1 className="text-xl font-bold">User Bank Details not found</h1>
        </div>
      );
    }
  
    const { primaryBank, secondaryBank, primaryCard, secondaryCard, upi } =
      userBankDetails;
  
    return (
      <div className="p-6">
        <div className="flex items-center mb-6">
          <button
            className="text-gray-700 font-normal hover:underline"
            onClick={() => navigate(-1)} // Navigate back
          >
            &larr; Back
          </button>
          <h1 className="text-2xl font-bold ml-4">Linked Bank Details</h1>
        </div>
  
        {/* Primary Bank Details */}
        <div className="bg-white shadow rounded p-6 mb-6">
          <h2 className="text-xl font-bold mb-4">Primary Bank Account Details</h2>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-bold mb-2">Account Type</label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={primaryBank.accountType}
                readOnly
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-2">Account No.</label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={primaryBank.accountNo}
                readOnly
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-2">IFSC</label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={primaryBank.ifsc}
                readOnly
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-2">Auto Pay</label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={primaryBank.autoPay}
                readOnly
              />
            </div>
          </div>
        </div>
  
        {/* Secondary Bank Details */}
        <div className="bg-white shadow rounded p-6 mb-6">
          <h2 className="text-xl font-bold mb-4">Secondary Bank Account Details</h2>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-bold mb-2">Account Type</label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={secondaryBank.accountType}
                readOnly
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-2">Account No.</label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={secondaryBank.accountNo}
                readOnly
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-2">IFSC</label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={secondaryBank.ifsc}
                readOnly
              />
            </div>
          </div>
        </div>
  
        {/* Primary Card Details */}
        <div className="bg-white shadow rounded p-6 mb-6">
          <h2 className="text-xl font-bold mb-4">Primary Card Details</h2>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-bold mb-2">
                Card Holder Name
              </label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={primaryCard.cardHolderName}
                readOnly
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-2">Card Number</label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={primaryCard.cardNumber}
                readOnly
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-2">Expiry Date</label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={primaryCard.expiryDate}
                readOnly
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-2">CVV</label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={primaryCard.cvv}
                readOnly
              />
            </div>
          </div>
        </div>
  
        {/* UPI Details */}
        <div className="bg-white shadow rounded p-6">
          <h2 className="text-xl font-bold mb-4">Saved UPI IDs Details</h2>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-bold mb-2">UPI App Name</label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={upi.appName}
                readOnly
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-2">UPI ID</label>
              <input
                type="text"
                className="w-full border rounded px-4 py-2 bg-gray-100"
                value={upi.upiId}
                readOnly
              />
            </div>
          </div>
        </div>
  
        {/* Action Buttons */}
        <div className="flex justify-end space-x-4 mt-6">
          <button className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">
            Reject
          </button>
          <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
            Verified
          </button>
        </div>
      </div>
    );
  };
  
  export default UserBankDetails;
  
