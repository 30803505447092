import React, { useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const CollectionCategories = () => {
  const navigate = useNavigate();

  // State for categories
  const [categories, setCategories] = useState([
    { name: "Index Funds", description: "Passive funds tracking indices." },
    { name: "Thematic Funds", description: "Sector-specific investments." },
    { name: "SIP with ₹100", description: "Small investments starting at ₹100." },
    { name: "Large Cap", description: "Investments in large-cap companies." },
    { name: "Mid Cap", description: "Mid-sized company investments." },
    { name: "Small Cap", description: "Small-cap company investments." },
    { name: "Tax Saver", description: "Tax-saving investments." },
    { name: "High Return", description: "High-risk, high-return funds." },
    { name: "Trending", description: "Funds trending with investors." },
  ]);

  const [newCategory, setNewCategory] = useState("");

  // Add a new category
  const handleAddCategory = () => {
    if (newCategory.trim()) {
      setCategories([
        ...categories,
        { name: newCategory.trim(), description: "Newly added category" },
      ]);
      setNewCategory("");
    }
  };

  // Remove a category
  const handleRemoveCategory = (index) => {
    const updatedCategories = categories.filter((_, i) => i !== index);
    setCategories(updatedCategories);
  };

  // Navigate to the detailed edit screen
  const handleEditCategory = (category) => {
    navigate("/edit-category", { state: category }); // Pass category details as state
  };

  // Save categories
  const handleSave = () => {
    console.log("Categories saved:", categories);
    alert("Categories saved successfully!");
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className="p-6 bg-gray-100">
      {/* Back Button */}
      <button
        className="mb-6 bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 flex items-center"
        onClick={() => navigate(-1)}
      >
        ← Back
      </button>

      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Collection Categories</h1>
        <div className="flex items-center space-x-4">
          <button
            className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300"
            onClick={() => console.log("Export functionality")}
          >
            Export
          </button>
          <button
            className="bg-black text-white px-4 py-2 rounded hover:bg-gray-800"
            onClick={() => navigate("/add-new-category")} // Navigate to AddNewCategory
          >
            + Add New Category
          </button>
        </div>
      </div>

      {/* Categories List */}
      <div className="bg-white shadow rounded p-4">
        {categories.map((category, index) => (
          <div
            key={index}
            className="flex justify-between items-center mb-4 border-b pb-2 cursor-pointer hover:bg-gray-50"
            onClick={() => handleEditCategory(category)} // Navigate to detailed view
          >
            <input
              type="text"
              value={category.name}
              readOnly
              className="w-full bg-transparent border-none outline-none"
            />
            <div className="flex items-center space-x-4">
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering row click
                  console.log("Edit functionality");
                }}
              >
                <FaEdit />
              </button>
              <button
                className="text-red-500 hover:text-red-700"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering row click
                  handleRemoveCategory(index);
                }}
              >
                <FaTrash />
              </button>
            </div>
          </div>
        ))}

        {/* Save Button */}
        <button
          className="bg-orange-500 text-white px-6 py-2 rounded hover:bg-orange-600 mt-4"
          onClick={handleSave}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default CollectionCategories;
