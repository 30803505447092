export const users = [
    {
      id: "SW01",
      orderId: "A123456789012ABC1ABCDE",
      name: "Axis bluechip fund direct plan growth",
      type: "One-Time",
      date: "11-Oct-2024",
      status: "Pending",
      paidVia: "Google Pay (Bank name XX1234)",
      navDate: "11-Sep-2024",
      folioNo: "1236467234",
      category: "Mid Cap",
      typeFund: "Equity",
      investedAmount: "₹500",
      unitsAllocated: "15.9",
    },
    {
      id: "SW02",
      orderId: "A123456789012ABC1ABDEF",
      name: "HDFC Mid Cap Fund",
      type: "SIP",
      date: "10-Oct-2024",
      status: "Completed",
      paidVia: "Paytm (Bank name XX5678)",
      navDate: "10-Oct-2024",
      folioNo: "987654321",
      category: "Large Cap",
      typeFund: "Equity",
      investedAmount: "₹1,000",
      unitsAllocated: "20.0",
    },
    {
      id: "SW03",
      orderId: "A123456789012ABC1ABCDG",
      name: "SBI Bluechip Fund",
      type: "Redeem",
      date: "09-Oct-2024",
      status: "Rejected",
      paidVia: "UPI Transfer (Bank name XX4321)",
      navDate: "09-Oct-2024",
      folioNo: "4567890123",
      category: "Mid Cap",
      typeFund: "Debt",
      investedAmount: "₹3,000",
      unitsAllocated: "25.0",
    },
  ];
  